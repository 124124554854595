<template>
    <div class="deviceCash" :style="{minHeight:clientHeight}">
        <div >
            <div class="card">
                <h5 class="deviceInfoTitle">站点档案</h5>
                    <div> <span class="type">风险告知</span>
                    </div>
                    <div class="payImgs">
                        <van-uploader v-model="fileList"  accept="image" :max-count="5"
                                      :after-read="afterRead" :before-delete="deletePic" />
                    </div>
                    <div> <span class="type">升级材料</span>
                    </div>
                    <div class="payImgs">
                        <van-uploader v-model="fileList2"  accept="image" :max-count="5"
                                      :after-read="afterRead2" :before-delete="deletePic2" />
                    </div>
                    <div> <span class="type">退出材料</span>
                    </div>
                    <div class="payImgs">
                        <van-uploader v-model="fileList3"  accept="image" :max-count="5"
                                      :after-read="afterRead3" :before-delete="deletePic3" />
                    </div>
                <div> <span class="type">取款登记本</span>
                </div>
                <div class="payImgs">
                    <van-uploader v-model="fileList4"  accept="image" :max-count="5"
                                  :after-read="afterRead4" :before-delete="deletePic4" />
                </div>
                <div> <span class="type">站长十严禁</span>
                </div>
                <div class="payImgs">
                    <van-uploader v-model="fileList5"  accept="image" :max-count="5"
                                  :after-read="afterRead5" :before-delete="deletePic5" />
                </div>
                <div> <span class="type">存款保险宣传</span>
                </div>
                <div class="payImgs">
                    <van-uploader v-model="fileList6"  accept="image" :max-count="5"
                                  :after-read="afterRead6" :before-delete="deletePic6" />
                </div>
                <div> <span class="type">合规经营承诺书</span>
                </div>
                <div class="payImgs">
                    <van-uploader v-model="fileList7"  accept="image" :max-count="5"
                                  :after-read="afterRead7" :before-delete="deletePic7" />
                </div>
            </div>


            <div class="btnDiv">
                <div class="cancelBtn" @click="cancelAction">取消</div>
                <div class="commitBtn" @click="commitAction">确定</div>
            </div>
        </div>





    </div>

</template>

<script>
    import common from "../../utils/common";
    import JsZip from "jszip";
    import {getSiteFileData, saveSiteFiles,uploadZipAndAlreadyImg} from "../../utils/api";

    export default {
        name: "siteFileInfo",
        data(){
            return {
                cashInfo:{},
                payImgs:[],
                fileList:[],
                fileList2:[],
                fileList3:[],
                fileList4:[],
                fileList5:[],
                fileList6:[],
                fileList7:[],
                stationNo:this.$route.query.stationNo,
                uploadZipImgList:[],
                uploadZipImgList2:[],
                uploadZipImgList3:[],
                uploadZipImgList4:[],
                uploadZipImgList5:[],
                uploadZipImgList6:[],
                uploadZipImgList7:[],
                fileImg:[],
                fileImg2:[],
                fileImg3:[],
                fileImg4:[],
                fileImg5:[],
                fileImg6:[],
                fileImg7:[],
                fileImgUrls:[],
                fileImgUrls2:[],
                fileImgUrls3:[],
                fileImgUrls4:[],
                fileImgUrls5:[],
                fileImgUrls6:[],
                fileImgUrls7:[],
                clientHeight:'667px',
                stationFileList:[]
            }
        },
        mounted() {
            this.clientHeight = (document.body.clientHeight)+'px'
            this.getSiteFileData()
        },
        methods:{
            getSiteFileData(){
                getSiteFileData({stationNo:this.stationNo }).then(res=>{
                    console.log("res", res)
                    if (res.data.stationFileList){
                        this.stationFileList = res.data.stationFileList
                        if(this.stationFileList==null){
                            return;
                        }
                        var baseUrl = process.env.NODE_ENV != 'production' ? 'http://124.70.167.116:20081' + '/file/' : window.location.origin + '/file/'
                        var tokenUrl = '?Authorization=' + common.getItem('wtToken')

                        for(var i = 0;i < this.stationFileList.length;i++){
                            var thisItem = this.stationFileList[i];
                            console.log("thisItem",thisItem)
                            if(thisItem.fileType=='riskFiles') {
                                var tempFile = JSON.parse(thisItem.files);
                                for (var j = 0; j < tempFile.length; j++) {
                                    var files = tempFile[j];
                                    this.fileList.push({
                                        'url': baseUrl + files + tokenUrl
                                    })
                                    this.fileImg.push(files)
                                }
                            }
                            if(thisItem.fileType=='upgradeFiles') {
                                var tempFile = JSON.parse(thisItem.files);
                                for (var k = 0; k < tempFile.length; k++) {
                                    var files2 = tempFile[k];
                                    this.fileList2.push({
                                        'url': baseUrl + files2 + tokenUrl
                                    })
                                    this.fileImg2.push(files2)
                                }
                            }
                            if(thisItem.fileType=='exitFiles') {
                                var tempFile = JSON.parse(thisItem.files);
                                for (var l = 0; l < tempFile.length; l++) {
                                    var files3 = tempFile[l];
                                    this.fileList3.push({
                                        'url': baseUrl + files3 + tokenUrl
                                    })
                                    this.fileImg3.push(files3)
                                }
                            }
                            if(thisItem.fileType=='WithdrawalBookFiles') {
                                var tempFile = JSON.parse(thisItem.files);
                                for (var l = 0; l < tempFile.length; l++) {
                                    var files4 = tempFile[l];
                                    this.fileList4.push({
                                        'url': baseUrl + files4 + tokenUrl
                                    })
                                    this.fileImg4.push(files4)
                                }
                            }
                            if(thisItem.fileType=='tenProhibitionsFiles') {
                                var tempFile = JSON.parse(thisItem.files);
                                for (var l = 0; l < tempFile.length; l++) {
                                    var files5 = tempFile[l];
                                    this.fileList5.push({
                                        'url': baseUrl + files5 + tokenUrl
                                    })
                                    this.fileImg5.push(files5)
                                }
                            }
                            if(thisItem.fileType=='depositInsuranceFiles') {
                                var tempFile = JSON.parse(thisItem.files);
                                for (var l = 0; l < tempFile.length; l++) {
                                    var files6 = tempFile[l];
                                    this.fileList6.push({
                                        'url': baseUrl + files6 + tokenUrl
                                    })
                                    this.fileImg6.push(files6)
                                }
                            }
                            if(thisItem.fileType=='commitmentLetterFiles') {
                                var tempFile = JSON.parse(thisItem.files);
                                for (var l = 0; l < tempFile.length; l++) {
                                    var files7 = tempFile[l];
                                    this.fileList7.push({
                                        'url': baseUrl + files7 + tokenUrl
                                    })
                                    this.fileImg7.push(files7)
                                }
                            }
                        }

                    }
                }).catch(()=>{

                })
            },
            afterRead (file, detail) {
                // 大于15M
                if (file.file.size > 20 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于20M的图片')
                    return
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 500 * 1024) {

                    let namearr = file.file.name.split('.')

                    let name = '.' + namearr[namearr.length - 1]

                    _this.uploadZipImgList.push({ data: file.content, name: name })
                    return
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)

                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {

                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList.push({ data: dataURL, name: name })

                        })
                    }
                }
            },
            deletePic (file, pic) {
                this.fileList.splice(pic.index,1)
                if (file.url){
                    this.fileImg.splice(pic.index,1)
                }else {
                    this.uploadZipImgList.splice(pic.index - this.fileImg.length,1)
                }
            },
            commitAction(){
                let self = this
                if (this.uploadZipImgList.length>0||this.uploadZipImgList2.length>0||this.uploadZipImgList3.length>0||this.uploadZipImgList4.length>0||this.uploadZipImgList5.length>0||this.uploadZipImgList6.length>0||this.uploadZipImgList7.length>0) {
                    console.log("this.uploadZipImgList", this.uploadZipImgList)
                    let imgName = 'riskInformedImg', img2Name = 'upgradeImg', img3Name = 'exitImg', img4Name = 'WithdrawalBook', img5Name = 'tenProhibitions', img6Name = 'depositInsurance', img7Name = 'commitmentLetter'
                    var zip = new JsZip();
                    var img = zip.folder("images");
                    for (var i = 0; i < this.uploadZipImgList.length; i++) {
                        img.file(imgName + '_' + i + this.uploadZipImgList[i].name,
                            this.uploadZipImgList[i].data.substring(this.uploadZipImgList[i].data.indexOf(",") + 1), {base64: true});
                    }
                    for (var j = 0; j < this.uploadZipImgList2.length; j++) {
                        img.file(img2Name + '_' + j + this.uploadZipImgList2[j].name,
                            this.uploadZipImgList2[j].data.substring(this.uploadZipImgList2[j].data.indexOf(",") + 1), {base64: true});
                    }
                    for (var k = 0; k < this.uploadZipImgList3.length; k++) {
                        img.file(img3Name + '_' + k + this.uploadZipImgList3[k].name,
                            this.uploadZipImgList3[k].data.substring(this.uploadZipImgList3[k].data.indexOf(",") + 1), {base64: true});
                    }
                    for (var l = 0; l < this.uploadZipImgList4.length; l++) {
                        img.file(img4Name + '_' + l + this.uploadZipImgList4[l].name,
                            this.uploadZipImgList4[l].data.substring(this.uploadZipImgList4[l].data.indexOf(",") + 1), {base64: true});
                    }
                    for (var m = 0; m < this.uploadZipImgList5.length; m++) {
                        img.file(img5Name + '_' + m + this.uploadZipImgList5[m].name,
                            this.uploadZipImgList5[m].data.substring(this.uploadZipImgList5[m].data.indexOf(",") + 1), {base64: true});
                    }
                    for (var g = 0; g < this.uploadZipImgList6.length; g++) {
                        img.file(img6Name + '_' + g + this.uploadZipImgList6[g].name,
                            this.uploadZipImgList6[g].data.substring(this.uploadZipImgList6[g].data.indexOf(",") + 1), {base64: true});
                    }
                    for (var f = 0; f < this.uploadZipImgList7.length; f++) {
                        img.file(img7Name + '_' + f + this.uploadZipImgList7[f].name,
                            this.uploadZipImgList7[f].data.substring(this.uploadZipImgList7[f].data.indexOf(",") + 1), {base64: true});
                    }
                    let json = {}
                    json[imgName] = self.fileImgUrls
                    json[img2Name] = self.fileImgUrls2
                    json[img3Name] = self.fileImgUrls3
                    json[img4Name] = self.fileImgUrls4
                    json[img5Name] = self.fileImgUrls5
                    json[img6Name] = self.fileImgUrls6
                    json[img7Name] = self.fileImgUrls7
                    let fileAleradyJson = JSON.stringify(json)


                    zip.generateAsync({type: "blob"}).then(function (content) {
                        uploadZipAndAlreadyImg(content, self.stationNo, fileAleradyJson).then(res => {
                            if (res.code === 200 && (res.data.businessCode === 200)) {
                                console.log('压缩后的内容', res.data)

                                let arr = res.data[imgName]
                                let arr2 = res.data[img2Name]
                                let arr3 = res.data[img3Name]
                                let arr4 = res.data[img4Name]
                                let arr5 = res.data[img5Name]
                                let arr6 = res.data[img6Name]
                                let arr7 = res.data[img7Name]

                                self.fileImgUrls = arr
                                self.fileImgUrls2 = arr2
                                self.fileImgUrls3 = arr3
                                self.fileImgUrls4 = arr4
                                self.fileImgUrls5 = arr5
                                self.fileImgUrls6 = arr6
                                self.fileImgUrls7 = arr7
                                console.log("图片url：", self.fileImgUrls, self.fileImgUrls2, self.fileImgUrls3,self.fileImgUrls4,self.fileImgUrls5,self.fileImgUrls6)
                                self.saveAction()
                            }
                        }).catch(() => {
                        })

                    }, e => {
                        this.$toast('上传图片异常')
                    });
                }else {
                    this.saveAction()
                }
            },
            saveAction(){
                let p = {
                    riskFiles : JSON.stringify(this.fileImg.concat(this.fileImgUrls)),
                    upgradeFiles: JSON.stringify(this.fileImg2.concat(this.fileImgUrls2)),
                    exitFiles: JSON.stringify(this.fileImg3.concat(this.fileImgUrls3)),
                    WithdrawalBookFiles: JSON.stringify(this.fileImg4.concat(this.fileImgUrls4)),
                    tenProhibitionsFiles: JSON.stringify(this.fileImg5.concat(this.fileImgUrls5)),
                    depositInsuranceFiles:JSON.stringify(this.fileImg6.concat(this.fileImgUrls6)),
                    commitmentLetterFiles:JSON.stringify(this.fileImg7.concat(this.fileImgUrls7)),
                    stationNo:this.stationNo,
                }
                saveSiteFiles(p).then(res=>{
                    if (res.code == 200 && (res.data.businessCode === 200)) {
                        this.$toast('提交成功')
                        this.$router.back()
                    }

                })
            },
            cancelAction(){
                this.$router.back()
            },
            afterRead2 (file, detail) {
                // 大于15M
                if (file.file.size > 20 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于20M的图片')
                    return;
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList2.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 500 * 1024) {
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    _this.uploadZipImgList2.push({ data: file.content, name: name })
                    return;
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)
                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {
                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList2.push({ data: dataURL, name: name })
                        })
                    }
                }
            },
            deletePic2 (file, pic) {
                this.fileList2.splice(pic.index,1)
                if (file.url){
                    this.fileImg2.splice(pic.index,1)
                }else {
                    this.uploadZipImgList2.splice(pic.index - this.fileImg2.length,1)
                }
            },
            afterRead3 (file, detail) {
                // 大于15M
                if (file.file.size > 20 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于20M的图片')
                    return;
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList3.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 500 * 1024) {
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    _this.uploadZipImgList3.push({ data: file.content, name: name })
                    return;
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)
                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {
                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList3.push({ data: dataURL, name: name })
                        })
                    }
                }
            },
            deletePic3 (file, pic) {
                this.fileList3.splice(pic.index,1)
                if (file.url){
                    this.fileImg3.splice(pic.index,1)
                }else {
                    this.uploadZipImgList3.splice(pic.index - this.fileImg3.length,1)
                }
            },
            afterRead4 (file, detail) {
                // 大于15M
                if (file.file.size > 20 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于20M的图片')
                    return;
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList4.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 500 * 1024) {
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    _this.uploadZipImgList4.push({ data: file.content, name: name })
                    return;
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)
                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {
                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList4.push({ data: dataURL, name: name })
                        })
                    }
                }
            },
            deletePic4 (file, pic) {
                this.fileList4.splice(pic.index,1)
                if (file.url){
                    this.fileImg4.splice(pic.index,1)
                }else {
                    this.uploadZipImgList4.splice(pic.index - this.fileImg4.length,1)
                }
            },
            afterRead5 (file, detail) {
                // 大于15M
                if (file.file.size > 20 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于20M的图片')
                    return;
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList5.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 500 * 1024) {
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    _this.uploadZipImgList5.push({ data: file.content, name: name })
                    return;
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)
                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {
                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList5.push({ data: dataURL, name: name })
                        })
                    }
                }
            },
            deletePic5 (file, pic) {
                this.fileList5.splice(pic.index,1)
                if (file.url){
                    this.fileImg5.splice(pic.index,1)
                }else {
                    this.uploadZipImgList5.splice(pic.index - this.fileImg5.length,1)
                }
            },
            afterRead6 (file, detail) {
                // 大于15M
                if (file.file.size > 20 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于20M的图片')
                    return;
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList6.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 500 * 1024) {
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    _this.uploadZipImgList6.push({ data: file.content, name: name })
                    return;
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)
                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {
                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList6.push({ data: dataURL, name: name })
                        })
                    }
                }
            },
            deletePic6 (file, pic) {
                this.fileList6.splice(pic.index,1)
                if (file.url){
                    this.fileImg6.splice(pic.index,1)
                }else {
                    this.uploadZipImgList6.splice(pic.index - this.fileImg6.length,1)
                }
            },
            afterRead7 (file, detail) {
                // 大于15M
                if (file.file.size > 20 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于20M的图片')
                    return;
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList7.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 500 * 1024) {
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    _this.uploadZipImgList7.push({ data: file.content, name: name })
                    return;
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)
                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {
                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList7.push({ data: dataURL, name: name })
                        })
                    }
                }
            },
            deletePic7 (file, pic) {
                this.fileList7.splice(pic.index,1)
                if (file.url){
                    this.fileImg7.splice(pic.index,1)
                }else {
                    this.uploadZipImgList7.splice(pic.index - this.fileImg7.length,1)
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .deviceCash{
        padding: 15px 15px 120px 15px;
        background: #F7F8F9;
        .deviceInfoTitle{
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1E1E1E;
            padding: 12px 0;
        }
        .flexbetewwn{
            justify-content: space-between;
        }
        .deviceItem{
            background: #ffffff;
            div{
                border-left: 4px solid #3F7C53 ;
                border-radius:3px ;
                padding: 12px 24px 12px 20px;
                border-bottom:1px solid #e8e8e8 ;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .type{

                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #272829;

                }
                .cash{

                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #6B6B6B;
                }


            }
            p{
                background: #ffffff;
                margin-bottom: 12px;
                padding: 13px 20px ;
                label{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #272829;
                    display: inline-block;
                    vertical-align: middle;
                }

                span{

                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #3F7C53;
                    display: inline-block;
                    vertical-align: middle;
                }

            }

        }
        .cashInfo{
            background: #ffffff;
            padding: 0 15px;
            .cashExplain{
                padding: 12px 9px;
                border-bottom: 1px solid #e8e8e8;
                display: flex;
                justify-content: space-between;
                align-items: center;
                label{
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #1E1E1E;
                }
                span{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #3F7C53;
                }

            }
            .cashAccount{
                padding-top: 22px;
                padding-left: 9px;
                label{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9B9B9B;
                }
                span{
                    margin-left: 40px;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #272829;
                }

            }
            .account{
                padding-left: 9px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #272829;
            }
            .bgDiv{
                padding:11px  0 11px 14px ;
                background: #F8FAFD;
                border-radius: 8px;
                p{
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #272829;

                }
            }
            .tip{
                padding-left: 9px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
                margin:  12px  0 0 0;
            }

        }
        .payImgs{
            padding: 22px 15px 20px 24px;
            background: #ffffff;
            label{
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
                display: inline-block;
                vertical-align: top;
                margin-right: 55px;
            }
            p{
                padding-top: 8px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
            }

        }
        .payImgs2{
            padding: 25px 15px 20px 24px;
            background: #ffffff;
            label{
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
            }
            p{
                padding-top: 8px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
            }
        }
        .btnDiv{
            background: #ffffff;
            position: absolute;
            left: 0;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            padding:12px;
            width: 100%;
            div{
                width:calc(50% - 6px);
                text-align: center;
                padding: 12px 0;
            }
            .cancelBtn{

                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                background: #FFFFFF;
                border-radius: 4px;
                border: 1px solid #E5E5E5;
            }
            .commitBtn{

                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                background: #3F7C53;
                border-radius: 4px;
            }
        }
        .payInfo{
            background: #FFFFFF;
            padding: 12px 24px;
            h5{

                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #1E1E1E;
            }
            p{
                padding: 6px 0;
                margin: 0;
                letter-spacing: 1px;
                label{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9B9B9B;
                }
                span{
                    margin-left: 40px;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #272829;
                }

            }
            .payImgs{

            }
        }
        .kong{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 60px;
            img{
                width: 210px;
            }
            p{
                margin-top: 30px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #616161;
                text-align: center;
                letter-spacing: 1px;
            }

        }
    }

</style>
<style>
    .deviceCash  .van-uploader__wrapper--disabled{
        opacity: 1;
    }
</style>
